<template>
  <el-main>
    <div class="default-plugin">
      <div class="item" v-for="(item, index) in recommend_list" :key="index" @click="jump(item)">
        <div class="name">
          <el-image v-if="index == 0" :src="require('@/assets/marketing/coupon_icon.png')"></el-image>
          <el-image v-else-if="index == 1" :src="require('@/assets/marketing/wx_icon.png')"></el-image>
          <el-image v-else :src="require('@/assets/marketing/live_icon.png')"></el-image>
          {{ item.name }}
        </div>
        <div class="introduction">{{ item.plugin_desc }}</div>
        <div class="btn">{{ item.type ? '去试试>' : '开发中' }}</div>
      </div>
    </div>
    <el-tabs v-model="classification_id" @tab-click="handleClick">
      <el-tab-pane v-for="(className, index) in marketingClass" :key="index" :label="className.name" :name="String(className.id)">
        <div class="plugin-list" v-for="(list, y) in componentsList" :key="y">
          <div class="title">
            <span></span>
            {{ list.name }}
          </div>
          <ul>
            <li v-for="(item, index) in list.list" :key="index" @click="jump(item)">
              <img :src="item.image" alt="" />
              <div class="plugin-name">
                <div>
                  {{ item.name }}
                  <p>{{ item.type ? '去试试>' : '开发中' }}</p>
                </div>
                <div>{{ item.plugin_desc }}</div>
              </div>
            </li>
          </ul>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      recommend_list: [],
      classification_id: '0',
      marketingClass: [],
      componentsList: [],
    };
  },
  created() {
    this.getMarketingClass();
    this.getMarketingPlugin();
  },
  methods: {
    handleClick() {
      this.getMarketingPlugin();
    },
    jump(val) {
      if (val.type) {
        this.$router.push(val.wxroute);
      } else {
        this.$message.warning('功能开发中');
      }
    },
    getMarketingClass() {
      this.$axios.post(this.$api.push.marketingClass).then(res => {
        if (res.code == 0) {
          this.marketingClass = res.result.list;
        }
      });
    },
    getMarketingPlugin() {
      this.$axios
        .post(this.$api.push.marketingPlugin, {
          classification_id: this.classification_id,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            let marketingClass = this.marketingClass;
            let arr = [];
            for (let i in marketingClass) {
              if (marketingClass[i].id > 0) {
                let obj = {};
                let val = list.filter(item => item.classification_id == marketingClass[i].id);
                if (val.length) {
                  obj.list = val;
                  obj.name = marketingClass[i].name;
                  arr.push(obj);
                }
              }
            }
            this.componentsList = arr;
          } else {
            this.$message.error(res.msg);
          }
          this.recommend_list = res.result.recommend_list;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .default-plugin {
    display: flex;
    margin-bottom: 10px;
    .item {
      width: 400px;
      height: 148px;
      border-radius: 16px;
      margin-right: 24px;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 24px;
      cursor: pointer;
      &:nth-child(1) {
        background: url('~@/assets/marketing/card1.png') no-repeat;
        .btn {
          color: #ff9861;
        }
      }
      &:nth-child(2) {
        background: url('~@/assets/marketing/card2.png') no-repeat;
        .btn {
          color: #4ccd73;
        }
      }
      &:nth-child(3) {
        background: url('~@/assets/marketing/card3.png') no-repeat;
        .btn {
          color: #3b91fe;
        }
      }
      .name {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 12px;
        .el-image {
          margin-right: 10px;
        }
      }
      .introduction {
        color: #fff;
        opacity: 0.6;
        margin-bottom: 20px;
      }
      .btn {
        width: 84px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #ffffff;
        border-radius: 16px;
      }
    }
  }
  /deep/ .el-tabs__nav-wrap {
    padding-left: 30px;
  }
  /deep/ .el-tabs__item {
    color: #979899;
  }
  /deep/ .el-tabs__item.is-active {
    color: #333333;
    font-weight: 600;
  }
  /deep/ .el-tabs__active-bar {
    background: #1467ff;
  }
  .plugin-list {
    &:first-child {
      margin-top: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      font-weight: bold;
      span {
        width: 3px;
        height: 14px;
        background: #1467ff;
        margin-right: 8px;
      }
    }
    ul {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 280px;
        height: 104px;
        border: 1px solid #f2f3f5;
        border-radius: 8px;
        margin-right: 24px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover {
          p {
            color: #1467ff;
            border-color: #1467ff !important;
          }
        }
        img {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .plugin-name {
          flex: 1;
          div:nth-child(1) {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            justify-content: space-between;
            p {
              width: 64px;
              height: 20px;
              border: 1px solid #e3e4e6;
              line-height: 20px;
              text-align: center;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 400;
            }
          }
          div:nth-child(2) {
            font-size: 12px;
            color: #979899;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
